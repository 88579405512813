.mobileSearchTeamFeedbackRoot {
    display: flex;
    padding: 3px 6px 3px 6px;
    max-width: 100%;
    min-width: 350px;
    height: 35px;
    margin-bottom: 13px;
    background: white;
    box-shadow: 5px 5px 21px 4px #00000014;
    border-radius: 30px;
    justify-content: flex-start;
    align-items: center;

    .icon {
        font-size: 17px;
        color: rgba(33, 33, 33, 0.6);
    }

    .input {
        background: transparent;
        font-size: 13px;
        width: 100%;
        outline: none;
        border: none;
    }
}
